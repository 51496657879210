import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f95da74e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "pending-topup-list" }
const _hoisted_2 = {
  key: 0,
  class: "section-card",
  id: "pending-top-up-list"
}
const _hoisted_3 = { class: "custom-card-title title-card" }
const _hoisted_4 = { class: "top-up-info" }
const _hoisted_5 = {
  key: 1,
  class: "section-card",
  id: "pending-top-up-list"
}
const _hoisted_6 = { class: "custom-card-title title-card" }
const _hoisted_7 = { class: "top-up-info" }
const _hoisted_8 = {
  key: 2,
  class: "section-card"
}
const _hoisted_9 = { class: "custom-card-title" }
const _hoisted_10 = { class: "top-up-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionItem = _resolveComponent("TransactionItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pendingMyUnpaidTopUpList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$gettext("My unpaid top-up requests")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$gettext("The following top up requests needs to be paid or canceled")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingMyUnpaidTopUpList, (topup) => {
            return (_openBlock(), _createBlock(_component_TransactionItem, {
              key: topup,
              class: "pending-top-up-item",
              transaction: topup,
              onClick: ($event: any) => (_ctx.openModal(topup))
            }, null, 8, ["transaction", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.pendingOthersUnpaidTopUpList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$gettext("Pending top-up requests")), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$gettext("The following top up requests await payment by third party")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingOthersUnpaidTopUpList, (topup) => {
            return (_openBlock(), _createBlock(_component_TransactionItem, {
              key: topup,
              class: "pending-top-up-item",
              transaction: topup,
              onClick: ($event: any) => (_ctx.openModal(topup))
            }, null, 8, ["transaction", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.pendingPaidTopUpList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$gettext("Top up waiting admin validation")), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$gettext(
            "The following top up have been paid and are waiting for an administrator of your local currency to validate them."
          )), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingPaidTopUpList, (topup) => {
            return (_openBlock(), _createBlock(_component_TransactionItem, {
              key: topup,
              transaction: topup,
              onClick: ($event: any) => (_ctx.openModal(topup))
            }, null, 8, ["transaction", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}